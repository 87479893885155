// export const changeUserName = (name) => {
//     return {
//         type: "CHANGE_USERNAME",
//         payload: name
//     }
// }

export const changeUserInfo = (userInfo) => {
    return {
        type: "CHANGE_USERINFO",
        payload: userInfo
    }
}